@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html,
body {
  background-color: #000;
  /* width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; */
}
